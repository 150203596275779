import type { Nullish } from "~/@types/generics"
import type { SbRichText } from "~/components/ui/RichText/_data/schema"

export function stringIsNotEmpty(str: string | number | string[] | boolean | null | undefined) {
  return str !== "" && str !== null && str !== undefined && typeof str === "string"
}

export function isRTFilled(richText?: Nullish<SbRichText>) {
  if (!richText) {
    return false
  }

  return richText.content?.filter((item) => item.content)?.length > 0
}

export function rTFilledOrNull(richText?: Nullish<SbRichText>) {
  return isRTFilled(richText) ? richText : null
}
