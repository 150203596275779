import type { DetailedHTMLProps, ElementType, HTMLAttributes } from "react"
import { render, type RenderOptions as StoryblokRenderOptions } from "storyblok-rich-text-react-renderer"

import type { ExplicitAny, Nullish } from "~/@types/generics"
import { isRTFilled } from "~/lib/storyblok/utils/check-empty-string"
import type { SbRichText } from "~/components/ui/RichText/_data/schema"

interface RenderOptions extends StoryblokRenderOptions {
  blokResolvers?: {
    [key: string]: (props: ExplicitAny) => JSX.Element | null
  }
}
export interface RichTextProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  as?: ElementType
  render: Nullish<SbRichText>
  options?: RenderOptions
}
/**
 * Richtext component
 * @param props
 * @returns Component
 */
function RichText({ ref, className, as: Tag = "div", style, render: renderText, options }: RichTextProps) {
  return isRTFilled(renderText) ? (
    <Tag ref={ref} className={className} style={style}>
      {render(renderText, options)}
    </Tag>
  ) : null
}

export default RichText
